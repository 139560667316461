import type { App } from 'vue';
import * as Sentry from '@sentry/vue';

enum ErrorHnadlerErrorType {
  vue = 'vue',
  win = 'window',
}

export type ErrorHandlerLogData = {
  type: ErrorHnadlerErrorType;
  data: {
    info?: string;
    message: string;
    trace: Array<string>;
    url: string;
  };
};

/**
 * Отлавливает ошибки приложения и прокидывает их в слушатели
 */
class ErrorHnadler {
  sentry: typeof Sentry | undefined;
  context: App;
  listeners: Array<CallableFunction>;

  constructor(app: App, sentry: typeof Sentry | undefined) {
    this.sentry = sentry;
    this.context = app;
    this.listeners = [];

    this.handleVue();
    this.handleWindow();
  }

  /**
   * Подписка на событие ошибки
   * @param {CallableFunction} cb - функция, которая будет вызванна слушателем
   * @returns void
   */
  onError(cb: CallableFunction) {
    this.listeners.push(cb);
  }

  /**
   * Вызывает все слушатели ошибок
   * @param {ErrorHandlerLogData} data - Данные ошибки
   * @param {Error | undefined} e - Объект с ошибкой
   * @returns void
   */
  fire(data: ErrorHandlerLogData, e: Error | undefined) {
    this.listeners.map((cb: CallableFunction) => cb(e, data));
  }

  /**
   * Логирует ошибку
   * @param {ErrorHandlerLogData} data - Данные ошибки
   * @param {Error | undefined} e - Объект с ошибкой
   * @returns void
   */
  capture(data: ErrorHandlerLogData, e: Error | undefined) {
    this.fire(data, e);
    if (this.sentry) this.sentry.captureException(e);
  }

  /**
   * Хендлер JS ошибок
   * @returns void
   */
  handleWindow() {
    window.onerror = (
      message: string | Event,
      _url: string | undefined,
      _line: number | undefined,
      _col: number | undefined,
      e: Error | undefined
    ) => {
      let trace: Array<string> = [];
      if (e?.stack)
        trace = e!.stack
          .toString()
          .split('\n')
          .map((item) => item.trim());

      this.capture(
        {
          type: ErrorHnadlerErrorType.win,
          data: {
            message: String(message),
            trace: trace,
            url: window.location.href,
          },
        },
        e
      );
    };
  }

  /**
   * Хендлер ошибок в компонентах Vue
   * @returns void
   */
  handleVue() {
    if (!this?.context?.config) return;
    this.context.config.errorHandler = (e: unknown, _vm, info) => {
      if (!e || !(e instanceof Error)) return;
      let trace: Array<string> = [],
        message = '';

      if (e?.stack)
        trace = e!.stack
          .toString()
          .split('\n')
          .map((item) => item.trim());
      if (e?.message) message = e!.message.toString();

      this.capture(
        {
          type: ErrorHnadlerErrorType.vue,
          data: {
            info: info,
            message: message,
            trace: trace,
            url: window.location.href,
          },
        },
        e
      );
    };
  }
}

export default (app: App, sentry: typeof Sentry | undefined) => new ErrorHnadler(app, sentry);
