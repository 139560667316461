import * as Sentry from '@sentry/vue';
import config from '@/config';
import type { Router } from 'vue-router';
import type { App } from 'vue';

export default (app: App, router: Router) => {
  const initSentry = (): typeof Sentry | undefined => {
    const dsn: string = config.sentryDsn;
    if (!dsn || !router) return;

    // функция для тестирования отправки ошибок в sentry
    // window.sentryTest('test message text')

    if (window !== undefined && window)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.sentryTest = (message: string) => {
        const button = document.createElement('button');
        const app = document.querySelector('#app');
        if (!button || !app) return;
        button.innerText = 'Отправить тестовую ошибку';
        button.addEventListener('click', () => {
          throw new Error(`Test error to sentry: ${message}`);
        });
        app.prepend(button);
      };

    Sentry.init({
      app,
      dsn,
      /* release: version, */
      initialScope: {
        tags: { app: config.sentryApp },
      },
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    Sentry.setContext('app', { name: config.sentryApp /*  release: version */ });

    app.provide('sentry', Sentry);

    return Sentry;
  };

  return {
    initSentry,
  };
};
