import config from '@/config';
import router from '@/router';

export const openOAuth = (showExpiredPage = false): void => {
  const baseUrl = config.lkEndpoint;
  const accessToken = router.currentRoute.value.query.access_token as string;

  const data: Record<string, string> = {
    id: config.sessionsClientId,
  };

  let url;
  const params = new URLSearchParams(data);

  if (showExpiredPage) {
    url = baseUrl + '/user/expired?' + params.toString();
  } else {
    url = baseUrl + '/auth?' + params.toString();
  }

  location.href = url;
};
