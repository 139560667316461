import axios, { AxiosResponse } from 'axios';
import User from '../models/User';
import router from '@/router';
import { openOAuth } from '@/utils/openOauth';
import config from '@/config';

export default {
  namespaced: true,
  state() {
    return {
      /**
       * Объект авторизованного пользователя
       */
      user: new User(),

      /**
       * Объект авторизованного пользователя из lk
       */
      userLk: {},

      /**
       * Текущий Access token
       */
      accessToken: '',

      /**
       * Текущие запросы
       */
      promises: {},

      /**
       * Контроллер для отмены запросов
       */
      abortController: new AbortController(),

      /**
       * Таймер для завершения сессии пользователя
       */
      logoutTimer: null,

      /**
       * Таймер для отображения плашки с предупреждением о скором окончании сессии
       */
      logoutModalTimer: null,

      /**
       * Флаг отображения плашки с предупреждением о скором окончании сессии
       */
      logoutModalShow: false,
    };
  },
  mutations: {
    /**
     * Заполнение данных пользователя
     *
     * @param state
     * @param data Объект данных, полученный от API
     */
    setUserData(state: Record<string, any>, data: Record<string, any>): void {
      state.user.id = data.user.id;
      state.user.login = data.user.login;
      state.user.email = data.user.email;
      state.user.gln = data.user.gln;
      state.user.phone = data.user.phone;
      state.user.name = data.user.name;
      state.user.role = data.user.role;
      state.user.pro = data.user.pro == 1;
      state.user.tokens = data.user.tokens_by_api;
      state.user.declineReasons = data.user.decline_reasons_by_api;
      state.user.loggedIn = data.loggedIn;
      state.user.membershipStatus = data.user.active_membership;
      state.user.membershipActiveTill = data.user.membership_active_till;
      state.user.companyName = data.user.company ? data.user.company.name : '';
      state.user.companyInn = data.user.company ? data.user.company.inn : '';
      state.user.companyGln = data.user.company ? data.user.company.gln : '';
      state.user.fullName = typeof data.user.full_name !== 'undefined' ? data.user.full_name : '';
    },

    setPromise(state: Record<string, any>, data: Record<string, any>): void {
      state.promises[data.promiseName] = data.promise;
    },

    setUserLk(state: Record<string, any>, data: Record<string, any>): void {
      state.userLk = data;
    },

    setAccessToken(state: Record<string, any>, token: string): void {
      state.accessToken = token;
    },

    /**
     * Сброс контроллера для отмены запросов
     *
     * @param state
     */
    resetAbortController(state: Record<string, any>): void {
      state.abortController = new AbortController();
    },

    /**
     * Скрыть предпреждение о сессии
     *
     * @param state
     */
    hideLogoutModal(state: Record<string, any>): void {
      state.logoutModalShow = false;
    },

    /**
     * Показать предпреждение о сессии
     *
     * @param state
     */
    showLogoutModal(state: Record<string, any>): void {
      state.logoutModalShow = true;
    },
  },
  actions: {
    /**
     * Попытка авторизации
     *
     * @param context
     * @param data Данные для авторизации. Логин (login) и пароль (password)
     */
    async loginAttempt({ commit }: { commit: any }, data: Record<string, string>): Promise<boolean> {
      commit('setLoading', { flagName: 'login', flagState: true }, { root: true });

      try {
        await axios.post('auth/login', data);

        return true;
      } catch (errors) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'login', flagState: false }, { root: true });
      }
    },

    async getAccessToken({ commit }: { commit: any; state: any }): Promise<any> {
      return await axios.get('auth/access-token').then((r: any) => {
        commit('setAccessToken', r?.data?.access_token);
        return r?.data;
      });
    },

    /**
     * Получение данных пользователя
     *
     * @param context
     */
    async getUser(
      { state, commit, dispatch, rootState }: { state: any; dispatch: any; rootState: any; commit: any },
      { force = false, isOAuthComponent = false } = {}
    ): Promise<any> {
      if (location.pathname !== '/oauth' || (location.pathname == '/oauth' && isOAuthComponent)) {
        const errorStatuses = [401];
        try {
          if (state.user.id && !force) return true;
          if (state.promises.user && !force) {
            return state.promises.user;
          }

          const promise = axios
            .get('auth/user', {
              signal: state.abortController.signal,
            })
            .then((response) => {
              commit('setUserData', { user: response.data, loggedIn: true });
              commit('setPromise', { promiseName: 'user', promise: false });

              return true;
            })
            .catch((error) => {
              if (errorStatuses.includes(error?.response?.status) && error?.response?.data?.code == 0) {
                return openOAuth();
              }
              return false;
            });

          commit('setPromise', { promiseName: 'user', promise: promise });
          return promise;
        } catch (errors) {
          commit('setUserData', { user: new User(), loggedIn: false });
          return false;
        }
      }
    },

    /**
     * Получение данных пользователя из LK
     *
     * @param context
     */
    async getUserFromLk({ commit }: { state: any; dispatch: any; commit: any }): Promise<any> {
      try {
        return axios
          .get(`${config.lkEndpoint}/user/info`)
          .then((response) => {
            commit('setUserLk', response.data);

            return response.data;
          })
          .catch(() => {
            return false;
          });
      } catch (errors) {
        return false;
      }
    },

    /**
     * Выход из системы
     */
    async logout({ commit }: { commit: any }): Promise<any> {
      try {
        await axios({
          url: 'api/v1/sessions',
          method: 'delete',
          baseURL: config.sessionsHost,
          data: {
            client_id: config.sessionsClientId,
          },
        }).then(() => {
          commit('setUserData', { user: new User(), loggedIn: false });
        });
        return true;
      } catch (errors) {
        return false;
      }
    },

    /**
     * Авторизация через OAuth
     * @param context
     * @param code Код для запроса токена
     */
    async oauth(_context: any, code: string): Promise<any> {
      try {
        const response = await axios.post<any, AxiosResponse<{ success: boolean; redirect: string }>>(
          'auth/oauth',
          {
            code: code,
            redirectUri: location.protocol + '//' + location.host + config.oauthRedirectUrl,
          },
          {
            timeout: 30000,
          }
        );

        if ('success' in response.data && !response?.data?.success) {
          if (typeof response.data.redirect !== 'undefined') {
            location.href = response.data.redirect;
          }

          return false;
        }

        return true;
      } catch (errors) {
        return false;
      }
    },

    /**
     * Проверка подтверждения данных пользователя
     *
     * @param context
     * @param data
     */
    async checkUserData({ commit }: { commit: any }, data: Record<string, any>): Promise<Record<string, string>> {
      commit('setLoading', { flagName: 'auth', flagState: true }, { root: true });

      try {
        const response: Record<string, any> = await axios.post('users/check-data/' + data.type, {
          value: data.value,
          code: data.code,
        });
        const result = response.data.result;

        return result;
      } catch (errors) {
        return { status: 'error' };
      } finally {
        commit('setLoading', { flagName: 'auth', flagState: false }, { root: true });
      }
    },

    /**
     * Отмена запросов
     *
     * @param context
     */
    async cancelRequests({ state, commit }: { state: any; commit: Function }): Promise<void> {
      state.abortController.abort();
      commit('resetAbortController');
    },

    /**
     * Обновление access токена
     *
     * @param context
     * @param force Пропустить проверку времени последнего запроса
     */
    async refreshSession({ state, dispatch }: { state: any; dispatch: Function }, force = false): Promise<any> {
      if (state.logoutModalShow || !state.user.loggedIn) return;

      try {
        if (!force) {
          // Cooldown на 1 минуту
          const currentTimestamp = Math.floor(Date.now() / 1000);
          if (localStorage.getItem('last_session_refresh') === null || +localStorage.getItem('last_session_refresh')! < currentTimestamp - 60) {
            localStorage.setItem('last_session_refresh', '' + currentTimestamp);
          } else {
            return;
          }
        }

        return axios({
          method: 'put',
          url: '/api/v1/sessions',
          baseURL: config.sessionsHost,
          data: {
            client_id: config.sessionsClientId,
          },
        })
          .then((response: Record<string, any>) => {
            if (typeof response?.data == 'undefined') return;

            if ('desktop_end_time' in response?.data?.data) {
              const datetime = Date.parse(response?.data.data.desktop_end_time);
              dispatch('setLogoutTimer', datetime);
            }

            return true;
          })
          .catch(() => {
            return;
          });
      } catch (errors) {
        return false;
      }
    },

    /**
     * Установка и обработка таймера сессии
     *
     * @param context
     * @param secondsLeft Кол-во секунд до завершения сессии
     */
    setLogoutTimer({ dispatch, commit, state }: { dispatch: Function; commit: Function; state: any }, sessionEndTimestamp: number): void {
      if (state.logoutTimer !== null) {
        clearTimeout(state.logoutTimer);
      }
      if (state.logoutModalTimer !== null) {
        clearTimeout(state.logoutModalTimer);
      }

      const secondsLeft = Math.round((sessionEndTimestamp - new Date().getTime()) / 1000);
      if (secondsLeft > 0) {
        // Если осталось меньше 40 минут сессии, то модальдное окно всплывет через 80% от оставшегося времени.
        // Если осталось более 40 минут, то модальное окно появится за 20 минут до окончания сессии.
        const secondsToLogoutModal = secondsLeft > 2400 ? secondsLeft - 1200 : secondsLeft * 0.8;

        state.logoutModalTimer = setTimeout(async () => {
          commit('showLogoutModal');
        }, secondsToLogoutModal * 1000);
      }

      state.logoutTimer = setTimeout(async () => {
        await axios({
          method: 'get',
          url: '/api/v1/sessions?client_id=' + config.sessionsClientId,
          baseURL: config.sessionsHost,
        }).then((response: Record<string, any>) => {
          if (typeof response.data?.success == 'undefined' || !response.data.success) {
            openOAuth(true);
          } else {
            dispatch('refreshSession');
          }
          return true;
        });
      }, secondsLeft * 1000);
    },
  },
  modules: {},
};
